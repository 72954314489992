import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "./Style/Home.css";
import { FaUserAlt } from "react-icons/fa";
import user from "./images/user.png"

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const CustomArrows = () => {
  const sliderRef = useRef(null);
  let url = process.env.REACT_APP_URL;
  const [testimonial, setTesimonial] = useState([]);
  const getTestimonial = async () => {
    try {
      let data = await fetch(`${url}/testimonial`);
      data = await data.json();
      setTesimonial(data.data);
      // console.log(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTestimonial();
  },[]);
   
  


  const settings = {
    
    className: "center",
    centerPadding: "60px",
    centerMode: true,
    speed: 500,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true, // Enable center mode
  // appendDots: (dots) => (
  //   <div>
  //     <ul style={{ marginBottom: "0" }}> {dots} </ul>
  //   </div>
  // ),
  customPaging: (i) => (
    <div
      style={{
        // width: "30px",
        // height: "8px",
        background: i === 2 ? "#F9A526" : "#888", // Customize active slide color
        borderRadius: "4px",
      }}
    />
  ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="py-5" style={{ background: "black" }}>
      <div className="container">
        <h2 className="text-center testimonials">Testimonials</h2>
        <Slider ref={sliderRef} {...settings}>
          {testimonial?.length &&
            testimonial.map((e) => {
              return (
                e && (
                  <div key={e?._id} className="testimonial  "   >
                    <div className="">
                      <div className="row">
                        <div className=" text-center" >
                          <div className="profile mx-2" >
                            {e?.image?
                            <img
                            src={`${url}/testimonial/${e?.image}`}
                            className="user"
                            alt=""
                            />:
                            <img
                              className="user"
                              src={user}
                              style={{backgroundColor:"black"}}
                              alt=""
                            />

                          }
                            <h3 className="testiname">{e?.name}</h3>
                            <h4 className="co-spans"> {e?.designation} </h4>
                            <blockquote className="pt-2">
                              {/* <i
                                className="fa-solid fa-quote-left fa-2xl px-2"
                                style={{ color: "#F9A526" }}
                              ></i> */}
                              {e?.description}
                              {/* {(e?.description).substring(0, 200)}... */}
                              {/* <i
                                className="fa-solid fa-quote-right fa-2xl px-2"
                                style={{ color: "#F9A526" }}
                              ></i> */}
                            </blockquote>
                            
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
        </Slider>
      </div>
    </section>
  );
};

export default CustomArrows;
