import React, { useEffect, useState } from "react";
import "./Style/AllProductModel.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const AllProductModel = () => {
  const [product, setProduct] = useState({});
  const [category, setCategory] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const navigate = useNavigate();
  const { slug, id } = useParams();
  let url = process.env.REACT_APP_URL;
  const getCategory = async (d) => {
    try {
      let data = await fetch(`${url}/category/${d}`);
      data = await data.json();
      setCategory(data.data[0].products);
      let index = data.data[0].products.findIndex((x) => x._id == id);
      // console.log(index,"index");
      let Product = data.data[0].products.filter((e, i) => i > index);
      // console.log(Product);
      if (Product.length < 4) {
        Product = data.data[0].products.filter((e, i) => i < 4);
      } else {
        Product = Product.filter((e, i) => i < 4);
      }
      setFilteredProduct(Product);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllProductModel = async () => {
    try {
      let data = await fetch(`${url}/product/${slug}`);
      data = await data.json();
      if(!data.data){
        navigate("/*")
      }
      setProduct(data.data);
      // console.log(data.data);

      // console.log(data.data.category._id, "data.data.category._id");
      await getCategory(data.data.category._id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProductModel();
    // console.log(filteredProduct, "filteredProduct");
  }, [slug]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{product?.meta_title}</title>
        <meta name="description" content={product?.meta_description} />
        <link rel="canonical" href={`${url}/product/${slug}`} />
      </Helmet>
      <div style={{ overflow: "hidden" }} className="no-copy-text">
        <section
          className="service-details-page pdt-70 pdb-90 py-5   "
          style={{ width: "100%", background: "black" }}
        >
          <div className="container  ">
            <div className="row  ">
              <div className="col-xl-12 col-lg-12 py-3 box-length  allproductborder">
                <div className="service-detail-text ">
                  <div className="row d-flex">
                    <div className="  col-lg-6 col-xl-6   ms-auto mb-n20  mb-xxl-n22  ">
                      <div className="productborder">
                        <img
                          className="  container img-1280"
                          src={`${url}/product/${product.image}`}
                          alt="img error"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-xl-6"
                      style={{
                        textJustify: "inter-word",
                      }}
                    >
                      <div className="title-box">
                        <h1 className="allproduct-heading container">
                          {product.name}
                          <span>
                            {product?.caption?.length > 0 && (
                              <p style={{ fontSize: "20px" }}>
                                ({product?.caption})
                              </p>
                            )}
                          </span>
                        </h1>
                        <div className="pera-box">
                          <p className="allproduct-pera container">
                            {product.description}
                          </p>
                        </div>
                      </div>
                      <div className="available-box">
                        <h3 className="pt-3 container  avilable">
                          {product?.available?.length > 0 && (
                            <p>AVAILABLE IN</p>
                          )}
                        </h3>
                        <div className="product-varient">
                          {product?.available &&
                            product.available.map((e, i) => {
                              return (
                                <div
                                  key={e?._id}
                                  className="    pt-2 "
                                  // style={{ textAlign: "center" }}
                                >
                                  <svg
                                    className="px-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="152"
                                    height="141"
                                    viewBox="0 0 152 141"
                                    fill="none"
                                  >
                                    <path
                                      d="M150.656 86.7363C150.656 128.079 108.888 141 67.4648 141C26.0414 141 0.649154 128.079 0.649154 86.7363C0.649154 45.3935 -12.1244 -28.9825 75.6528 11.8787C163.43 52.7398 150.656 45.3935 150.656 86.7363Z"
                                      fill="#FFAD31"
                                    />
                                    <image
                                      className="container  "
                                      href={`${url}/variant/${e}`}
                                      alt="img error"
                                      x="0"
                                      y="0"
                                      width="150"
                                      height="130"
                                    />
                                  </svg>
                                  <div>
                                    {/* {product?.variant?.length > 0 ? ( */}
                                    <p
                                      className="captionmodel"
                                      style={{ marginBottom: "0" }}
                                    >
                                      {product.variant[i]}
                                    </p>
                                    {/* ) : (
                              <p>""</p>
                            )} */}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="service-details-page pdt-70 pdb-90 pb-5    "
          style={{ width: "100%", background: "black" }}
        >
          <div className="container  ">
            <div className="row  ">
              <div className="col-xl-12 col-lg-12 ">
                <div className="service-detail-text ">
                  <div className="row d-flex" style={{ alignItems: "center" }}>
                    {filteredProduct.map((e) => {
                      return (
                        <div
                          key={e._id}
                          onClick={() => {
                            navigate(`/product/${e?.slug}`);
                            window.scrollTo(0, 0);
                          }}
                          className="  col-lg-3 col-xl-3 my-3  ms-auto mb-n20  mb-xxl-n22 "
                          style={{ cursor: "pointer" }}
                        >
                          <div className="under-product item animated wow fadeIn ">
                            <img
                              style={{ width: "" }}
                              className="  container   "
                              src={`${url}/product/${e?.image}`}
                              alt=""
                            />
                          </div>
                          <div className="overlay title-overlay">
                            <p
                              style={{
                                minHeight: "60px",
                                display: "flex",
                                justifyContent: "center",

                                // background: "#131313",
                                padding: "5px 10px",
                                borderRadius: "20px",
                                // border: "1px solid #202020",
                                margin: "0px",
                                // margin:"10px 30px"
                              }}
                              className="text"
                            >
                              {e?.name}
                            </p>
                          </div>
                          {/* <div className=" px-3 " style={{paddingTop:"30px"}}>
                        <p  className="mobile-name"> {e?.name}</p>
                        </div> */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AllProductModel;
