import React, { useEffect, useRef, useState } from "react";
import "./Style/Blogpage.css";
import productbanner from "./images/productbanner.png";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blogpage = () => {
  const [blog, setBlog] = useState([]);
  const navigate=useNavigate()
  const { slug } = useParams();

  const scrollRef = useRef(null);

  let url = process.env.REACT_APP_URL;

  const getBlog = async () => {
    try {
      let response = await fetch(`${url}/blog/${slug}`);
      console.log("response Data", response);
      let data = await response.json();
      if(!data.data){
        navigate("/*")
      }
      setBlog(data.data);
      console.log("Blog Data Details", data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlog();
  }, [slug]);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{blog?.meta_title}</title>
        <meta name="description" content={blog?.meta_description} />
        <link rel="canonical" href={`${url}/blog/${slug}`} />
      </Helmet>
      <section
        className="no-copy-text"
        style={{ padding: "50px 0", backgroundColor: "black" }}
      >
        <div className="main-width">
          <div className="under1320width">
            <div className="all-page-padding">
              <div className="news-event-heading-pera">
                <h1 className="news-event-heading">{blog.title}</h1>
                <div className="news-event-page-img-1">
                  <img
                    src={`${url}/blog/${blog?.image}`}
                    alt={blog.image_alt}
                  />
                </div>
                <p className="news-event-heading-date">
                  {formatDate(blog.modifiedAt)}
                </p>
                <p
                  className="news-event-pera"
                  dangerouslySetInnerHTML={{ __html: blog.description }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogpage;
