import React, { useEffect, useRef, useState } from "react";
import "./Style/Product.css";
import productbanner from "./images/productbanner.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const Product = () => {
  const [category, setCategory] = useState({});
  const [producttextview, setProducttextview] = useState({});
  const navigate = useNavigate();

  const scrollRef = useRef(null);

  const handleCategoryClick = (categoryName) => {
    const categorySection = document.getElementById(categoryName);
    if (categorySection) {
      const yOffset = -140;
      const y =
        categorySection.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });

      const categoryElements = document.querySelectorAll(".list-product");
      categoryElements.forEach((element) => {
        element.classList.remove("fixed");
      });
      const clickedCategory = document.getElementById(categoryName);
      if (window.scrollY > "100vh") {
        clickedCategory.classList.add('fixed top:"500px');
      }
    }
  };

  let url = process.env.REACT_APP_URL;

  const getCategory = async () => {
    try {
      let data = await fetch(`${url}/category`);
      data = await data.json();
      setCategory(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getProducttextview = async () => {
    try {
      let data = await fetch(`${url}/product/text/view`);
      data = await data.json();
      setProducttextview(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategory();
    getProducttextview();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Products | Desi Kothi Ice Cream</title>
        <meta
          name="description"
          content="Explore DesiKothi's tempting ice cream flavors. From traditional favourites to seasonal thrills, each spoon is crafted with love. Delight in creamy excellence!"
        />
        <link rel="canonical" href={`${url}/product`} />
      </Helmet>
      <div style={{ overflow: "hidden" }} className="no-copy-text">
        <div>
          <img
            className="product-banner"
            src={`${url}/product/${producttextview[0]?.image}`}
            alt=""
          />
        </div>
        {producttextview?.length &&
          producttextview.map((e) => {
            return (
              e && (
                <section key={e?._id} style={{ background: "black" }}>
                  <div
                    className=" py-5 container"
                    style={{ background: "black" }}
                  >
                    <p className="productpera">{e?.text}</p>
                  </div>
                </section>
              )
            );
          })}
        <section className="pb-4 " style={{ background: "black" }}>
          <div className="">
            <div
              className=" catgory-row"
              // style={{ border: "1px solid #4B4B4B", borderRadius: "25px" }}
            >
              {category?.length &&
                category?.map(
                  (categoryItem) =>
                    categoryItem && (
                      <div key={categoryItem?._id} className=" ">
                        <div className="list-product " key={categoryItem.name}>
                          <p
                            className="btn btn1 producticecream "
                            onClick={() =>
                              handleCategoryClick(categoryItem.name)
                            }
                          >
                            {categoryItem.name}
                          </p>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        </section>

        {category?.length &&
          category?.map((categoryItem) => {
            return (
              categoryItem && (
                <div
                  className=""
                  id={categoryItem.name}
                  ref={scrollRef}
                  key={categoryItem.name}
                >
                  <section
                    className="service-details-page pdt-70 pdb-90  pt-4   "
                    style={{ width: "100%", background: "#000" }}
                  >
                    <div className="container  ">
                      <h1 className="from-our-menu pb-5">
                        {categoryItem.name}{" "}
                      </h1>
                      <div className="row  ">
                        <div className="col-xl-12 col-lg-12 ">
                          <div className="service-detail-text ">
                            <div
                              className="row d-flex"
                              style={{ alignItems: "center" }}
                            >
                              {categoryItem.products?.map((product) => {
                                return (
                                  product && (
                                    <div
                                      key={product?._id}
                                      className="col-lg-3 col-xl-3 pb-4"
                                      style={{
                                        textAlign: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        navigate(`/product/${product.slug}`);
                                        window.scrollTo(0, 0);
                                      }}
                                    >
                                      <div
                                        className="from-under "
                                        // style={{ height:"440px", padding: "16px 0" }}
                                      >
                                        {/* {home[0]?.bg[i]} */}
                                        <div className="homes-froms-unders ">
                                          <img
                                            style={{ pointerEvents: "none" }}
                                            className="libox container "
                                            src={`${url}/product/${product?.image}`}
                                            alt="img error"
                                          />
                                        </div>
                                        <div style={{ margin: "16px 0" }}>
                                          <p className="from-heading">
                                            {product.name}
                                          </p>

                                          {/* <p
                                        className="from-pera"
                                        style={{ color: "white" }}
                                      >
                                        {product.caption}
                                      </p> */}

                                          <p className="from-pera">
                                            {(product?.description).substring(
                                              0,
                                              50
                                            )}
                                            ...
                                          </p>
                                        </div>
                                        <div className="container">
                                          <hr style={{ color: "#FFAD31" }} />
                                        </div>

                                        <p
                                          className="  but-try-it"
                                          style={{ margin: "0" }}
                                        >
                                          <button
                                          // style={{ color: home[0]?.btn_color[i] }}
                                          >
                                            {" "}
                                            TRY IT{" "}
                                          </button>
                                        </p>
                                      </div>
                                      {/* <div
                                      className="product-under"
                                      
                                    >
                                      <div className="product-3-divs" >
                                        <img
                                        className="product-border-img"
                                          
                                          src={`${url}/product/${product?.image}`}
                                          alt="img error"
                                        />
                                      </div>
                                      <p className="product-heading ">
                                        {product.name}
                                      </p>
                                      
                                        <p className="product-pera ">
                                          {product.caption}
                                        </p>
                                      

                                      <p className="product-pera">
                                        {(product?.description).substring(
                                          0,
                                          50
                                        )}
                                        ...
                                      </p>
                                      <div className="container">
                                        <hr style={{ color: "#FFAD31" }} />
                                      </div>
                                      <p className="mb-3 but-try-it">
                                        {" "}
                                        <button> TRY IT </button>
                                      </p>
                                    </div> */}
                                    </div>
                                  )
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )
            );
          })}
      </div>
    </>
  );
};

export default Product;
