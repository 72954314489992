import React from "react";
import eventbanner from "./images/eventbanner.png";
import { useEffect, useState } from "react";
import "./Style/Event.css";
import EventModal from "./EventModal";
import { Helmet } from "react-helmet";

const Event = () => {
  let url = process.env.REACT_APP_URL;

  const [event, setEvent] = useState([]);
  const getEvent = async () => {
    try {
      let data = await fetch(`${url}/event`);
      data = await data.json();
      setEvent(data.data);
      // console.log(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEvent();
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Events | Desi Kothi Ice Cream </title>
        <meta
          name="description"
          content="Discover wonderful celebrations at DesiKothi Ice Cream! Find out upcoming ice cream events near you. Check our calendar and sign up for this enjoyable event!"
        />
        <link rel="canonical" href={`${url}/event`} />
      </Helmet>

      <div className="no-copy-text">
        <div>
          <img className="event-banner" src={eventbanner} alt="" />
        </div>
        <section className="py-5" style={{ background: "black" }}>
          {event?.length &&
            event.map((e) => {
              return (
                e && (
                  <div key={e?._id} className=" eventtext py-3">
                    <div className="">
                      <EventModal
                        image={e.image}
                        place={e.place}
                        title={e.title}
                        description={e.description}
                        date={e.date}
                        time={e.time}
                      />
                    </div>
                  </div>
                )
              );
            })}
        </section>
      </div>
    </>
  );
};

export default Event;
