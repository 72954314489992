import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import CustomArrows from "./Components/CustomArrows ";
import About from "./Components/About";
import Product from "./Components/Product";
import AllProductModel from "./Components/AllProductModel";
import Contact from "./Components/Contact";
import Event from "./Components/Event";

import Footer from "./Components/Footer";
import ScrollToTopButton from "./Components/ScrollToTopButton";
import { useEffect } from "react";
import Blog from "./Components/Blog";
import Blogpage from "./Components/Blogpage";

import CatalogPdf from "./Components/CataloguePDF";
import PagenotFound from "./Components/404";

function App() {
  // useEffect(()=>{
  //   document.addEventListener('contextmenu', event => event.preventDefault())
  //   document.onkeydown = function(event) {
  //     if(event.keyCode == 123) {
  //     return false;
  //     }
  //     if(event.ctrlKey && event.shiftKey && event.keyCode == 'I'.charCodeAt(0)){
  //     return false;
  //     }
  //     if(event.ctrlKey && event.shiftKey && event.keyCode == 'J'.charCodeAt(0)){
  //     return false;
  //     }
  //     if(event.ctrlKey && event.keyCode == 'U'.charCodeAt(0)){
  //     return false;
  //     }
  //     }

  // },[])
  return (
    <>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="CustomArrows" element={<CustomArrows />} />
          <Route path="about" element={<About />} />
          <Route path="product" element={<Product />} />

          <Route path="/product/:slug" element={<AllProductModel />} />
          <Route path="contact" element={<Contact />} />
          <Route path="event" element={<Event />} />
          <Route path="blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<Blogpage />} />

          <Route path="catalogue" element={<CatalogPdf />} />
          <Route path="*" element={<PagenotFound />} />
         


        </Routes>

        <Footer />
        <ScrollToTopButton />
      </div>
      
    </>
  );
}

export default App;
