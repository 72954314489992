import React from "react";
import "./Style/Footer.css";
import footerfadelogo from "./images/footerfadelogo.png";
import footeryellowlogo from "./images/footeryellowlogo.png";
import location from "./images/location.png";

const Footer = () => {
  return (
    <div className="no-copy-text">
      <footer className="foot foot1 py-3 ">
        <div className="footerContainer">
          <div className="containerst">
            <div className=" boxs">
              <img  style={{ width: "200px" }} src={footerfadelogo} alt="" />
              <img
                style={{ width: "200px" }}
                src={footeryellowlogo}
                alt=""
                className="hover-imgs"
              />
            </div>
          </div>
          <nav
            className="   justify-center footnavs "
            aria-label="Footer"
          >
            <div className="px-2 py-1 place-foot ">&#x2022; Melbourne</div>

            <div className=" px-2 py-1 place-foot">&#x2022; Sydney</div>

            <div className=" px-2 py-1 place-foot">&#x2022; Adelaide</div>

            <div className=" px-2 py-1 place-foot">&#x2022; Gold coast</div>

            <div className=" px-2 py-1 place-foot">&#x2022; Brisbane</div>

            <div className=" px-2 py-1 place-foot">&#x2022; Perth</div>
            <div className=" px-2 py-1 place-foot">&#x2022; Canberra</div>
            <div className=" px-2 py-1 place-foot">&#x2022; Darwin</div>
            <div className=" px-2 py-1 place-foot">&#x2022; Tasmania</div>
          </nav>

          <div className="kulu">
            
            <img style={{cursor:"pointer"}} className="manali2" src={location} alt="" />
          
            
            <img style={{cursor:"pointer"}} className="manali3" src={location} alt="" />
           
            <svg
              className="manali1"
              style={{ marginTop: "-30px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="110"
              viewBox="0 0 1920 110"
              fill="none"
            >
              <path
                d="M-2.09375 99.939C769.202 124.313 1189.91 102.13 1920 1.00011"
                stroke="#2B2B2B"
                strokeDasharray="10 10"
              />
            </svg>
          </div>
        </div>
        <div className="footerBottom">
          Copyright <span>&copy;</span> 2024 Desi Kothi Ice Cream 
        </div>
      </footer>
    </div>
  );
};

export default Footer;
