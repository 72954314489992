import React from "react";
import desi from "./images/desi.png";
import "./Style/Navbar.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [productcatalogue, setProductcatalogue] = useState({});
  const [menu, setMenu] = useState({});
  let url = process.env.REACT_APP_URL;

  const handleclick = () => {
    setClicked(!clicked);
  };

  const getMenu = async () => {
    try {
      let data = await fetch(`${url}/menu`);
      data = await data.json();
      setMenu(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const getProductcatalogue = async () => {
    try {
      let data = await fetch(`${url}/catalogue`);
      data = await data.json();
      setProductcatalogue(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const downloadFile = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  useEffect(() => {
    getMenu();
    getProductcatalogue();
  }, []);

  return (
    <>
      <nav className="sticky-top nav2 no-copy-text">
        <a href="/">
          <img className="desi-logo" src={desi} alt="" />
        </a>

        <div>
          <ul
            id="navbar"
            onClick={() => {
              setClicked(!clicked);
              window.scrollTo(0, 0);
            }}
            className={clicked ? "#navbar active" : "#navbar"}
          >
            <li className="naves">
              <Link className="  under active " to="/">
                <span className="do">HOME</span>
                <span className="hidden-span">HOME</span>
              </Link>
            </li>
            <li className="naves">
              <Link className="under" to="/about">
                <span className="do">ABOUT US</span>
                <span className="hidden-span">ABOUT US</span>
              </Link>
            </li>
            <li className="naves">
              <Link className="under" to="/product">
                <span className="do">OUR PRODUCT</span>
                <span className="hidden-span">OUR PRODUCT</span>
              </Link>
            </li>
            <li className="naves">
              <Link className="under" to="/event">
                <span className="do">EVENTS</span>
                <span className="hidden-span">EVENTS</span>
              </Link>
            </li>
            <li className="naves">
              <Link className="under" to="/blog">
                <span className="do">BLOGS</span>
                <span className="hidden-span">BLOGS</span>
              </Link>
            </li>
            <li className="naves">
              <Link className="under" to="/contact">
                <span className="do">CONTACT US</span>
                <span className="hidden-span">CONTACT US</span>
              </Link>
            </li>
            {menu && (
              <li
                className="nav-item pl"
                onClick={() =>
                  downloadFile(`${url}/menu/${menu?.menu}`, `${menu?.menu}`)
                }
              >
                <Link
                  className="nav-link stme meet"
                  key={menu?._id}
                  onClick={() => window.open(`${url}/menu/${menu?.menu}`)}
                  to={`${url}/menu/${menu?.menu}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-solid fa-bars px-1"></i>
                  Menu
                </Link>
              </li>
            )}
            <li className="nav-item pl">
              <Link className="nav-link stme" rel="noopener noreferrer" to="/catalogue">
                <i className="fa-solid fa-bars px-1"></i>
                Catalogue
              </Link>
            </li>

            {/* <li className="nav-item pl ">
              {menu && (
                <Link
                key={menu?._id}
                  className="nav-link stme meet"
                  onClick={() => window.open(`${url}/menu/${menu?.menu}`)}
                  to={`${url}/menu/${menu?.menu}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <i className="fa-solid fa-bars px-1"></i>
                  Store Menu
                </Link>
              )}
            </li> */}
            {/* <li className="nav-item pl">
              
              {productcatalogue &&  (
                <Link
                key={productcatalogue?._id}
                  className="nav-link stme"
                  onClick={() =>
                    window.open(
                      `${url}/catalogue/${productcatalogue?.catalogue}`
                    )
                  }
                  to={`${url}/catalogue/${productcatalogue?.catalogue}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <i className="fa-solid fa-bars px-1"></i>
                  Product Catalogue
                </Link>
              )}
            </li> */}
            <li className="nav-item  pl">
              <Link
                style={{ marginRight: "20px" }}
                className="instalinkt "
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.instagram.com/desikothiicecream?igsh=MWhnZ293ZGNtMGRzOA=="
              >
                <i className="fa-brands fa-instagram  fa-lg"></i>
              </Link>
              <Link
                className="fblinkt"
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.facebook.com/desikothiicecream"
              >
                <i className="fa-brands fa-facebook-f  fa-lg"></i>
              </Link>
            </li>
          </ul>
        </div>

        <div id="mobile" onClick={handleclick}>
          <i id="bar" className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
