import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./Style/Event.css";

function MyVerticallyCenteredModal(props) {
  const { image, title, place, description, date, time } = props;
  const [majorImage, setMajorImage] = useState(image[0] || "");

  let url = process.env.REACT_APP_URL;
  // useEffect(()=>{

  // },[majorImage])
  return (
    <Modal
   
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="close-btns" style={{}}></Modal.Header>
      <Modal.Body style={{ background: "black" }}>
        {/* <div
          className="titletext"
          style={{ textAlign: "center", fontWeight: "bolder" }}
        >
          {title}
        </div>
        <div
          className="placetext"
          style={{ textAlign: "center", fontWeight: "bolder" }}
        >
          {place}
        </div> */}
        <div
          className="middle no-copy-text"
          style={{  alignItems: "center" }}
        >
          <div className="" style={{ textAlign: "center" }}>
            <img
              style={{ justifyContent: "center"}}
             className="container eventsssssss-img"
              src={`${url}/event/${majorImage}`}
              alt="img error"
            />

            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                padding: "10px 0",
                cursor: "pointer",
              }}
            >
              {image?.map((e, i) => {
                return (
                  <>
                    <div onClick={() => setMajorImage(e)}>
                      <img
                        style={{ border: "1px solid #FFAD31", width: "50px" }}
                        src={`${url}/event/${e}`}
                        alt="img error"
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div >
            <div className="titletext" style={{ textAlign: "center" }}>
              {title}
            </div>
            <div className="placetext" style={{ textAlign: "center" }}>
              {place}
            </div>

            <div className="eventdescription">{description}</div>
            <div
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                fontSize: "25px",
                color: "#818181",
              }}
            >
              Date: {date}
            </div>
            <div
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                fontSize: "25px",
                color: "#818181",
              }}
            >
              Time: {time}
            </div>
          </div>
        </div>
        {/* <div
          style={{
            textAlign: "center",
            fontWeight: "bolder",
            fontSize: "25px",
            color: "#818181",
          }}
        >
          Date: {date}
        </div>
        <div
          style={{
            textAlign: "center",
            fontWeight: "bolder",
            fontSize: "25px",
            color: "#818181",
          }}
        >
          Time: {time}
        </div> */}
      </Modal.Body>
    </Modal>
  );
}

const EventModal = ({ image, place, title, description, date, time }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const majorImage = image.length > 0 ? image[0] : "";

  let url = process.env.REACT_APP_URL;
  return (
    <div className="no-copy-text"  >
      <div >
        <div className=" eventtext" onClick={() => setModalShow(true)} style={{cursor:"pointer"}}>
          <img
            style={{ borderRadius: "15px", border: "1px solid #FFAD31"}}
            onClick={()=>setModalShow(true)}
            className=" imageevent "
            src={`${url}/event/${majorImage}`}
            alt="img error"
            />
          <div className="imageevent mt-3"  style={{ display: "flex",justifyContent:"space-between"}} >
            <div style={{ display: "flex"}}  >
              <p  className="placemore mt-2  " style={{wordWrap:"break-word"}}  >{title}</p>
              {/* <p className="placemore">{place}</p> */}
            </div>
            <button  className="readmore btn btn1" style={{cursor: "pointer" }} >Click Here</button>{" "}
          </div>
        </div>{" "}
      </div>
      <MyVerticallyCenteredModal
        // style={{border: "1px solid yellow"}}
        show={modalShow}
        onHide={() => setModalShow(false)}
        image={image}
        title={title}
        place={place}
        description={description}
        date={date}
        time={time}
      />
    </div>
  );
};

export default EventModal;








