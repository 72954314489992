import React, { useEffect, useRef, useState } from "react";
import "./Style/Blog.css";
import blogbanner from "./images/DkBlogbanner.jpg";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blog = () => {
  const [blog, setBlog] = useState([]);
  const [producttextview, setProducttextview] = useState([]);
  const navigate = useNavigate();

  const scrollRef = useRef(null);

  let url = process.env.REACT_APP_URL;

  const getBlog = async () => {
    try {
      let response = await fetch(`${url}/blog`);
      console.log("response Data", response);
      let data = await response.json();
      const reversData = data.data.reverse();
      setBlog(reversData);

      // setBlog(data.data);
      console.log("Blog Data", data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getProducttextview = async () => {
    try {
      let data = await fetch(`${url}/product/text/view`);
      data = await data.json();
      setProducttextview(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlog();
    getProducttextview();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs | Desi Kothi Ice Cream</title>
        <meta
          name="description"
          content="Keep updated with DesiKothi Ice Cream's latest news and ice cream stories. Explore our blog for enjoyable insights, events, recipes, and much more!"
        />
        <link rel="canonical" href={`${url}/blog`} />
      </Helmet>
      <div style={{ overflow: "hidden" }} className="no-copy-text">
        <div>
          <img className="about-banner" src={blogbanner} alt="" />
        </div>

        <section
          className=""
          style={{ width: "100%", background: "#000", padding: "50px 0px" }}
        >
          <div className="main-width">
            <div className="under1320width">
              <div className="all-page-padding">
                <div className="card-flex">
                  {blog.length &&
                    blog.map((item) => {
                      return (
                        item && (
                          <div
                            className="card"
                            key={item.slug}
                            onClick={() => {
                              navigate(`/blog/${item.slug}`);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <img
                              src={`${url}/blog/${item.image}`}
                              alt={item.image_alt}
                            />
                            <p className="blog-date">
                              {new Date(item.createdAt).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </p>
                            <p className="from-heading">{item.title}</p>
                            {/* <p
                              className="from-pera"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.description.substring(0, 120) + "...",
                              }}
                            ></p> */}
                            <hr style={{ color: "#FFAD31" }} />
                            <p
                              className="but-try-it-blog"
                              style={{ margin: "0" }}
                            >
                              <button className="read-more-btn">
                                Read More <i className="fas fa-arrow-right"></i>
                              </button>
                            </p>
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Blog;
