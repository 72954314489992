import React from "react";
import { Link, useNavigate } from "react-router-dom";

const PagenotFound = () => {
  const navigate=useNavigate()
  return (
    <>
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center vh-100 text-center" style={{ background: 'black' }} >
        <h1 className="display-1 fw-bold" style={{color:"#ffad31"}}>404</h1>
        <div className="lead" style={{color:"#ffad31"}} >
          Ooops!!! The page you are looking for is not found
        </div>
        <button onClick={()=>navigate("/")} class="back-home" className="btn btn1 mt-3 mb-3" style={{ color: 'white' }}>
          Back to home
        </button>
      </div>
    </>
  );
};

export default PagenotFound;
