import React, { useEffect, useState } from "react";
import "./Style/Contact.css";
import "./Style/SelectDropDown.css";
import contactbanner from "./images/contactbanner.png";
import contact from "./images/contact.png";
import axios from "axios";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [allCity, setAllCity] = useState([]);
  const [message, setMessage] = useState("");
  const [outlet, setOutlet] = useState({});
  const [mainDistributor, setMainDistributor] = useState([]);
  const [subDistributor, setSubDistributor] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({});

  let url = process.env.REACT_APP_URL;
  let formData = new FormData();

  const getOutlet = async () => {
    try {
      let data = await fetch(`${url}/outlet`);
      data = await data.json();
      setOutlet(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getDistributer = async () => {
    try {
      let data = await fetch(`${url}/distributer`);
      data = await data.json();

      let mainD = data.data.filter((e) => e.city.toLowerCase() === "melbourne");
      setMainDistributor(mainD);

      let subD = data.data.filter((e) => e.city.toLowerCase() !== "melbourne");

      setSubDistributor(subD);
    } catch (error) {
      console.log(error);
    }
  };
  const postPutEvent = async () => {
    setIsLoading(true);

    const data = {
      name,
      email,
      phone,
      message,
      city,
    };

    if (!name || !email || !phone || !message || !city) {
      alert("Please enter All The Fields");
      setIsLoading(false);
      return;
    }
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("message", message);
    formData.append("city", city);
    image && formData.append("image", image);

    try {
      let res = await axios.post(
        `${process.env.REACT_APP_URL}/contact`,
        formData
      );
      // console.log(res.data);
      // let res = await fetch(`${url}/contact`, {
      //   method: "POST",
      //   headers: {
      //     "Content-type": "application/json; charset=UTF-8",
      //   },
      //   body: JSON.stringify(data),
      // });
      // res = await res.json();
      alert("mail sent");
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      document.getElementById("contactForm").reset();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCity = async () => {
    try {
      let data = await fetch(`${url}/city`);
      data = await data.json();
      // console.log(data);
      setAllCity(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOutlet();
    getDistributer();
    getCity();
  }, []);

  if (!Array.isArray(outlet)) {
    // Handle the case where outlet is not an array (e.g., set default value or show an error message)
    return null; // or render an error message
  }

  // Group outlets by city
  const outletsByCity = outlet.reduce((acc, curr) => {
    const cityKey = curr.city.name;
    acc[cityKey] = [...(acc[cityKey] || []), curr];
    return acc;
  }, {});
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Desi Kothi Ice Cream</title>
        <meta
          name="description"
          content="Contact DesiKothi ice cream for any questions or feedback you may have. Reach out to us—we're here to assist you every step of the way!"
        />
        <link rel="canonical" href={`${url}/contact`} />
      </Helmet>
    <div style={{ overflow: "hidden" }} className="no-copy-text">
      <div>
        <img className="contact-banner" src={contactbanner} alt="" />
      </div>
      <section
        className="service-details-page pdt-70 pdb-90 pb-5   "
        style={{ width: "100%", background: "black" }}
      >
        <div className="container  ">
          <div className="row  ">
            <h1 className="contactusheading py-3">Contact us</h1>
            <div className="col-xl-12 col-lg-12 contact-main-border ">
              <div className="service-detail-text ">
                <div className="row d-flex">
                  <div className="   col-lg-6 col-xl-6   ">
                    <div id="contact">
                      <h3 className="contactus">Contact us</h3>
                      <p className="contactpera">
                        Have a question about our delicious ice cream or looking
                        for the scoop on our latest flavors? We're just a
                        message away. Let's make your ice cream moments even
                        more delightful!
                      </p>
                      <form style={{ textAlign: "left" }} id="contactForm">
                        <div>
                          <label className="labels" htmlFor="">
                            Name*
                          </label>
                          <input
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            className="inputborder"
                            type="text"
                            required
                          />
                        </div>
                        <div className="col-lg-12 col-xl-12 ">
                          <div className="d-flex">
                            <div className="col-lg-6">
                              <div className="dividesection">
                                <label className="labels" htmlFor="">
                                  Email*
                                </label>
                                <input
                                  onChange={(e) => setEmail(e.target.value)}
                                  value={email}
                                  className="inputborder"
                                  type="email"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div className="dividesection">
                                <label className="labels" htmlFor="">
                                  Contact No*
                                </label>
                                <input
                                  onChange={(e) => setPhone(e.target.value)}
                                  value={phone}
                                  className="inputborder"
                                  type="tel"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="state-file col-lg-12 col-xl-12  ">
                          <div className="custom-select-wrapper col-lg-6 ">
                            <label className="labels " htmlFor="">
                              State*
                            </label>
                            <select
                              id="select"
                              className="custom-select inputborder"
                              onClick={() =>
                                document
                                  .getElementById("select")
                                  .classList.toggle("open")
                              }
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                            >
                              <option value={""}>Select</option>
                              <option value={"New South Wales"}>
                                New South Wales
                              </option>
                              <option value={"Queensland"}>Queensland</option>
                              <option value={"South Australia"}>
                                South Australia
                              </option>
                              <option value={"Tasmania"}>Tasmania</option>
                              <option value={"Victoria"}>Victoria</option>
                              <option value={"Western Australia"}>
                                Western Australia
                              </option>
                              <option value={"Australian Capital Territory"}>
                                Australian Capital Territory
                              </option>
                              <option value={"Northern Territory"}>
                                Northern Territory
                              </option>
                            </select>
                          </div>
                          <div className="col-lg-6 ">
                            <label className="labels py-1">Image</label>
                            <br />
                            <input
                              type="file"
                              name="image"
                              className="image-file"
                              onChange={(e) => setImage(e.target.files[0])}
                              style={{ color: "#ffff" }}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="labels" htmlFor="">
                            Message*
                          </label>
                          <textarea
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                            className="inputborder"
                            type="text"
                            required
                          ></textarea>
                        </div>
                      </form>
                      <div className="pt-2 text-center">
                        <button
                          className="btn btn1"
                          onClick={postPutEvent}
                          name="submit"
                          type="submit"
                          id="contact-submit"
                          data-submit="...Sending"
                          disabled={isLoading} // Disable the button when loading
                        >
                          {isLoading && (
                            <div
                              style={{ marginRight: "5px" }}
                              className="loader "
                            ></div>
                          )}
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="   col-lg-6 col-xl-6    ">
                    {/* <iframe
                      className="container"
                      src={`https://www.google.com/maps/d/embed?mid=1ER50Fw__0Klu8hDOFpXS10tTeyP0qo8&ehbc=2E312F&noprof=1`}
                      style={{
                        width: "100%",
                        height: "480px",
                        borderRadius: "20px",
                      }}
                    ></iframe> */}
                    <div className="gmapframe">
                      <iframe
                        className="container mapsg"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3149.475625471703!2d144.7218707826952!3d-37.87255826508698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad688ef6fc3278f%3A0x31d282b78d4a2b4f!2sFactory%2031%2F3%20Kilmur%20Rd%2C%20Hoppers%20Crossing%20VIC%203029%2C%20Australia!5e0!3m2!1sen!2sin!4v1706115104960!5m2!1sen!2sin"
                        // src="https://www.google.com/maps/d/embed?mid=1ER50Fw__0Klu8hDOFpXS10tTeyP0qo8&ehbc=2E312F&noprof=1"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        // style={{
                        //   width: "100%",
                        //   height: "528px",
                        // }}
                      ></iframe>
                    </div>
                    {/* <img
                    className="container"
                    src={contact}
                    style={{
                      width: "100%",
                      height: "480px",
                      borderRadius: "20px",
                    }}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {mainDistributor?.length &&
        mainDistributor.map((e) => {
          return (
            e &&
            e.city === "Melbourne" && (
              <section
                key={e?._id}
                className="pb-5  "
                style={{ background: "black" }}
              >
                <div className="container">
                  <div className="contact-border ">
                    <h1 className="contact-melbourne pt-5 contact-text-left">
                      {e?.city}
                    </h1>

                    <div className=" container  d-flex align-items-center contact-melbourne-left-align ">
                      {" "}
                      <i className="fa-solid fa-location-dot "></i>
                      <a
                        className="contact-melbourne-mail px-2"
                        href={`${e?.location_link}`}
                        target="_blan"
                        rel="nopper"
                      >
                        <span>{e?.address}</span>
                      </a>
                    </div>
                    <div className="pt-2 d-flex align-items-center contact-melbourne-left-align">
                      {" "}
                      <i className="fa-solid fa-envelope"></i>
                      <a
                        className="contact-melbourne-mail px-2"
                        href={`mailto:${e.mail}`}
                        target="_blan"
                        rel="nopper"
                      >
                        {e?.mail}
                      </a>
                    </div>
                    <div className="pb-5">
                      <h1 className="desi-kothi-1300 pt-2 contact-text-left ">
                        <span> {e?.text1} </span>
                      </h1>
                      <h1 className="desi-kothi-1300 contact-text-left">
                        {" "}
                        <a
                          href={`tel:${e?.text2}`}
                          rel="nopper"
                          style={{ textDecoration: "none", color: "#ffff" }}
                        >
                          <span> {e?.text2} </span>
                        </a>
                      </h1>
                    </div>
                  </div>
                </div>
              </section>
            )
          );
        })}

      <section
        className="service-details-page pdt-70 pdb-90   "
        style={{ width: "100%", background: "black" }}
      >
        <div className="container  ">
          <div className="row  ">
            <div className="col-xl-12 col-lg-12 ">
              <div className="service-detail-text ">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  {subDistributor?.length &&
                    subDistributor.map((e) => {
                      return (
                        <div key={e?._id} className="col-lg-4 col-xl-4 pb-4">
                          <div className="contact-box">
                            <h1 className=" sydney contact-sub-text-left">
                              {e?.city}
                            </h1>
                            <div className="pt-2 d-flex align-items-center">
                              {" "}
                              <i className="fa-solid fa-envelope"></i>
                              <a
                                className="contact-melbourne-mail px-2"
                                href={`mailto:${e?.mail}`}
                                target="_blan"
                                rel="nopper"
                              >
                                {e?.mail}
                              </a>
                            </div>
                            <div
                              className=" pt-2 container  d-flex align-items-center  "
                              style={{ paddingLeft: "2px" }}
                            >
                              {" "}
                              <i className="fa-solid fa-phone "></i>
                              <a
                                className="contact-melbourne-mail px-2"
                                href={`tel:${e.contact}`}
                                target="_blan"
                                rel="nopper"
                              >
                                <span>{e?.contact}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="service-details-page pdt-70 pdb-90 pb-5   dtt   "
        style={{ width: "100%", background: "black" }}
      >
        <div className="container  ">
          <div className="row  ">
            <h1 className="find-us"> Find Us </h1>
            {Object.keys(outletsByCity).map((cityName) => (
              <div
                key={cityName}
                className="col-xl-12 col-lg-12 mb-5  find-us-border"
              >
                <h1 className="find-us-melbourne">{cityName}</h1>
                <h1 className="find-us-outlet pb-3">Outlet Addresses</h1>
                <div className="service-detail-text ">
                  <div className="row d-flex" style={{ alignItems: "center" }}>
                    {outletsByCity[cityName].map((e) => (
                      <div
                        key={e?._id}
                        className="col-lg-4 col-xl-4 pb-4 "
                        style={{
                          textAlign: "justify",
                          textJustify: "inter-word",
                        }}
                      >
                        <div className="under-border">
                          <p className="contact-clayton">
                            <span style={{ color: "#FFF" }}> {e?.name}</span>{" "}
                            <br />
                            <span> {e?.landmark}</span>
                          </p>
                          <div className="pt-2 d-flex align-items-center">
                            {" "}
                            <i className="fa-solid fa-location-dot"></i>
                            <a
                              className="contact-melbourne-mail px-2"
                              href={`${e?.location_link}`}
                              target="_blan"
                              rel="nopper"
                            >
                              {e?.location}
                            </a>
                          </div>
                          <div
                            className=" pt-2 container  d-flex align-items-center  "
                            style={{ paddingLeft: "2px" }}
                          >
                            {" "}
                            <i className="fa-solid fa-phone "></i>
                            <a
                              className="contact-melbourne-mail px-2"
                              href={`tel:${e?.contact}`}
                              target="_blan"
                              rel="nopper"
                            >
                              <span>{e?.contact}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
    </>
  );
};

export default Contact;
