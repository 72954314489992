import React, { useState } from "react";
import "./Style/Contact.css";
import "./Style/Catalogue.css";
import "./Style/SelectDropDown.css";
import catalogBanner from "./images/DkCatalog.jpg";
import { useForm } from "react-hook-form";
import { FaUser, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { Helmet } from "react-helmet";

const CatalogPdf = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const url = process.env.REACT_APP_URL;

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const response = await fetch(`${url}/twoemail/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        alert("Emails sent successfully , Plz Check Your Mail attechment");
        reset();
      } else {
        alert("Failed to send mail");
      }
    } catch (error) {
      console.log(error);
      alert("Error sending emails: " + error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Catalogue | Desi Kothi Ice Cream</title>
        <meta
          name="description"
          content="Browse DesiKothi's ice cream catalogue. Discover a wide range of flavours and products crafted for pure indulgence. Find your favourites and treat yourself today!"
        />
        <link rel="canonical" href={`${url}/catalogue`} />
      </Helmet>
      <div style={{ overflow: "hidden" }} className="no-copy-text">
        <div>
          <img
            className="contact-banner"
            src={catalogBanner}
            alt="Contact Banner"
          />
        </div>
        <section
          className="service-details-page pdt-70 pdb-90 pb-5"
          style={{ width: "100%", background: "black" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <h1 className="contactusheading py-3">Our Catalogue</h1>
              <div className="col-xl-8 col-lg-8 contact-main-border bordr">
                <div className="service-detail-text">
                  <div id="contact">
                    {/* <h3 className="contactus">Catalogue</h3> */}
                    <p className="contactperas">
                      fill the details given below! you will shortly receive an
                      email of our delicious and wide range of products at your
                      fingertips.
                    </p>
                    <form
                      style={{ textAlign: "left" }}
                      id="contactForm"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="icon-cont mt-5">
                        <FaUser className="iconxx" />
                        <label className="labels" htmlFor="name">
                          Name
                        </label>
                        <input
                          {...register("name", { required: true })}
                          className="inputborder spass"
                          type="text"
                        />
                        {errors.name && (
                          <span className="error">This field is required</span>
                        )}
                      </div>
                      <div className="col-lg-12 col-xl-12">
                        <div className="">
                          <div className="col-lg-12">
                            <div className="dividesection icon-cont">
                              <FaEnvelope className="iconxx" />
                              <label className="labels" htmlFor="email">
                                Email
                              </label>
                              <input
                                {...register("email", { required: true })}
                                className="inputborder spass"
                                type="email"
                              />
                              {errors.email && (
                                <span className="error">
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 ">
                            <div className="dividesection icon-cont ">
                              <BsFillTelephoneFill className="iconxx" />
                              <label className="labels" htmlFor="contactNumber">
                                Contact No
                              </label>
                              <input
                                {...register("contactNumber", {
                                  required: "This field is required",
                                  pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Contact number must be 10 digits",
                                  },
                                  maxLength: {
                                    value: 10,
                                    message:
                                      "Contact number cannot exceed 10 digits",
                                  },
                                })}
                                className="inputborder spass"
                                type="tel"
                              />
                              {errors.contactNumber && (
                                <span className="error">
                                  {errors.contactNumber.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="state-file col-lg-12 col-xl-12">
                        <div className="custom-select-wrapper col-lg-12 icon-cont">
                          <FaMapMarkerAlt className="iconxx" />
                          <label className="labels" htmlFor="state">
                            State
                          </label>
                          <select
                            {...register("state", { required: true })}
                            className="custom-select inputborder state-input"
                          >
                            <option value="">Select</option>
                            <option value="New South Wales">
                              New South Wales
                            </option>
                            <option value="Queensland">Queensland</option>
                            <option value="South Australia">
                              South Australia
                            </option>
                            <option value="Tasmania">Tasmania</option>
                            <option value="Victoria">Victoria</option>
                            <option value="Western Australia">
                              Western Australia
                            </option>
                            <option value="Australian Capital Territory">
                              Australian Capital Territory
                            </option>
                            <option value="Northern Territory">
                              Northern Territory
                            </option>
                          </select>
                          {errors.state && (
                            <span className="error">
                              This field is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="pt-2 text-center">
                        <button
                          className="btn btn1"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading && (
                            <>
                              <span style={{ color: "red" }}>
                                <div
                                  style={{ marginRight: "5px" }}
                                  className="loader"
                                ></div>
                                Please wait....
                                <br />
                                <br />
                                Do not back & close window !!!
                              </span>
                            </>
                          )}
                          {!isLoading && "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CatalogPdf;
