import React from "react";
import { useEffect, useState } from "react";
import "./Style/Home.css";
import CustomArrows from "./CustomArrows ";
import { useNavigate } from "react-router-dom";
import Banner from "./Banner";
import { Helmet } from "react-helmet";

const Home = () => {
  const [home, setHome] = useState({});
  const [imageIndex, setImageIndex] = useState(0);

  const navigate = useNavigate();
  let url = process.env.REACT_APP_URL;

  const getHome = async () => {
    try {
      let data = await fetch(`${url}/home`);
      data = await data.json();
      setHome(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getHome();
    // window.scrollTo({top:0});
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Best Desi Ice Cream in Australia | Indian Ice Cream | Desi Kothi Ice
          Cream
        </title>
        <meta
          name="description"
          content="Welcome to DesiKothi, where every spoon tells a narration of luscious excellence. Explore our ice creams and find a place near you to enjoy delicious flavors"
        />
        <link rel="canonical" href={`${url}/`} />
      </Helmet>
      <div style={{ overflow: "hidden" }} className="no-copy-text">
        <Banner />
        <section
          className=" pb-5    "
          style={{ width: "100%", background: "black" }}
        >
          <div className="container  ">
            <h1
              className=" py-3 top-seller"
              data-aos="fade-down"
              data-aos-duration="2000"
            >
              Top Seller
            </h1>

            <div
              className=" top-div "
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              {home?.length &&
                home[0]?.top_seller?.map((e) => {
                  return (
                    e && (
                      <div
                        key={e?._id}
                        className="under-box py-4 main-divs "
                        // style={{ margin:" 0 4%"}}
                      >
                        <div className=" py-3 text-center ">
                          <img
                            style={{ pointerEvents: "none" }}
                            className="immm "
                            src={`${url}/product/${e?.image}`}
                            alt="img error"
                          />
                        </div>
                        <p className=" container top-under-all-heading pt-4">
                          {e?.name}
                        </p>
                        {/* {e?.caption?.length > 0 ? ( */}
                        <p
                          className=" container top-under-all-pera"
                          style={{ color: "#f9a526" }}
                        >
                          {e?.caption}
                        </p>
                        {/* ) : (
                                <p>""</p>
                              )} */}
                        <p className=" container top-under-all-pera">
                          {(e?.description).substring(0, 99)}...
                        </p>

                        <button
                          onClick={() => {
                            navigate(`/product/${e?.slug }`);
                            window.scrollTo(0, 0);
                          }}
                          className=" read-more"
                        >
                          Read More
                        </button>
                      </div>
                    )
                  );
                })}
            </div>

            {/* <div className="  col-lg-2 col-xl-2  ms-auto mb-n20  mb-xxl-n22  ">
                    <div
                      className="rotatey"
                      data-aos="fade-left"
                      data-aos-duration="2000"
                    >
                      <img
                        className="  container    "
                        src={leftcone}
                        alt="img error"
                      />
                    </div>
                  </div> */}
          </div>
          {/* </div>
            </div>
          </div>
        </div> */}
        </section>
        {home?.length &&
          home.map((e) => {
            return (
              e && (
                <section
                  key={e?._id}
                  className="service-details-page pdt-70 pdb-90 pb-5    "
                  style={{ width: "100%", background: "#000" }}
                >
                  <div className="container aboutborder ">
                    <div className="row  ">
                      <div className="col-xl-12 col-lg-12 ">
                        <div className="service-detail-text ">
                          <div
                            className="row d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <div className="  col-lg-5 col-xl-5  ms-auto mb-n20  mb-xxl-n22  ">
                              <div className="box-ponks">
                                <img
                                  style={{ pointerEvents: "none" }}
                                  className="  container  "
                                  src={`${url}/home/${e?.about_image}`}
                                  alt="img error"
                                />
                              </div>
                            </div>

                            <div
                              className="col-lg-7 col-xl-7"
                              style={{
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <h1
                                className="aboutus"
                                data-aos="fade-down"
                                data-aos-duration="2000"
                              >
                                About Us
                              </h1>
                              <p
                                data-aos="slide-up"
                                data-aos-duration="2000"
                                className="aboutpera"
                              >
                                {e?.about_us}
                              </p>
                              <div>
                                <a href="/About">
                                  <button className="btn btn1">
                                    Read More
                                  </button>
                                </a>
                              </div>
                            </div>

                            {/* <div className="col-lg-2 col-xl-2 ms-auto mb-n20  mb-xxl-n22">
                            <img
                              className="cupcream"
                              data-aos="slide-up"
                              data-aos-duration="2000"
                              src={cupcream}
                              alt="img error"
                            />
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )
            );
          })}
        {home?.length &&
          home.map((e) => {
            return (
              e && (
                <section
                  key={e?._id}
                  className="service-details-page pdt-70 pdb-90 pb-5  dtt   "
                  style={{ width: "100%", background: "#000" }}
                >
                  <div className="container classyborder ">
                    <div className="row  ">
                      <div className="col-xl-12 col-lg-12 ">
                        <div className="service-detail-text ">
                          <div
                            className="row d-flex"
                            style={{ alignItems: "center" }}
                          >
                            {/* <div className="col-xl-2">
                            <img
                              data-aos="fade-right"
                              data-aos-duration="2000"
                              className="leaf1"
                              src={leafright}
                              alt="img error"
                            />
                          </div> */}
                            <div
                              className="col-lg-7 col-xl-7 "
                              style={{
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <h1
                                className="classytrad"
                                data-aos="fade-down"
                                data-aos-duration="2000"
                              >
                                Classy Tradition
                              </h1>
                              <p
                                data-aos="slide-up"
                                data-aos-duration="2000"
                                className="aboutpera"
                              >
                                {e?.classy_tradition}
                              </p>
                              <div>
                                {/* <a href="/About">
                                <button className="btn btn1">Read More</button>
                              </a> */}
                              </div>
                            </div>

                            <div className="  col-lg-5 col-xl-5   ms-auto mb-n20  mb-xxl-n22  ">
                              <div>
                                <img
                                  style={{ pointerEvents: "none" }}
                                  className="  container mbut "
                                  src={`${url}/home/${e?.classy_tradition_image}`}
                                  alt="img error"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )
            );
          })}
        {home?.length &&
          home.map((e) => {
            return (
              e && (
                <section
                  key={e?._id}
                  className="service-details-page pdt-70 pdb-90 pb-5     "
                  style={{ width: "100%", background: "#000" }}
                >
                  <div className="container aboutborder ">
                    <div className="row  ">
                      <div className="col-xl-12 col-lg-12 ">
                        <div className="service-detail-text ">
                          <div
                            className="row d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <div className="  col-lg-5 col-xl-5  ms-auto mb-n20  mb-xxl-n22  ">
                              <div className="box-ponks">
                                <img
                                  style={{ pointerEvents: "none" }}
                                  className="  container  "
                                  src={`${url}/home/${e?.philosophy_image}`}
                                  alt="img error"
                                />
                              </div>
                            </div>

                            <div
                              className="col-lg-7 col-xl-7"
                              style={{
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <h1
                                className="Philosophyabout"
                                data-aos="fade-down"
                                data-aos-duration="2000"
                              >
                                Philosophy
                              </h1>
                              <p
                                data-aos="slide-up"
                                data-aos-duration="2000"
                                className="aboutpera"
                              >
                                {e?.philosophy}
                              </p>
                              <div>
                                {/* <a href="/About">
                                <button className="btn btn1">Read More</button>
                              </a> */}
                              </div>
                            </div>

                            {/* <div className="  col-xl-2">
                            <img
                              data-aos="fade-left"
                              data-aos-duration="2000"
                              className="leaf2"
                              src={leafleft}
                              alt="img error"
                            />
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )
            );
          })}
        <section
          className="service-details-page pdt-70 pdb-90    "
          style={{ width: "100%", background: "#000" }}
        >
          <div className="container  ">
            <h1 className="from-our-menu">From Our Menu</h1>
            <div className="row  ">
              <div className="col-xl-12 col-lg-12 ">
                <div className="service-detail-text ">
                  <div className="row d-flex" style={{ alignItems: "center" }}>
                    {home?.length &&
                      home[0]?.our_menu?.map((e, i) => {
                        if (i % 2 !== 0) {
                          return (
                            <div
                              key={e?._id}
                              onClick={() => {
                                navigate(`/product/${e?.slug}`);
                                window.scrollTo(0, 0);
                              }}
                              data-aos="slide-up"
                              data-aos-duration="2000"
                              className="col-lg-3 col-xl-3 fromms"
                              style={{
                                textJustify: "inter-word",
                                alignItems: "center",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                className="from-under"
                                style={{ background: home[0]?.bg[i] }}
                              >
                                {/* {home[0]?.bg[i]} */}
                                <div className="homes-froms-unders">
                                  <img
                                    style={{ pointerEvents: "none" }}
                                    className="libox container "
                                    src={`${url}/product/${e?.image}`}
                                    alt="img error"
                                  />
                                </div>
                                <div style={{ margin: "16px 0" }}>
                                  <p
                                    className="from-heading"
                                    style={{ color: home[0]?.title_color[i] }}
                                  >
                                    {e?.name}
                                  </p>
                                  {/* {e?.caption?.length > 0 ? ( */}
                                  {/* <p
                                className="from-pera"
                                style={{ color: "white" }}
                              >
                                {e?.caption}
                              </p> */}
                                  {/* ) : (
                                <p>""</p>
                              )} */}

                                  <p
                                    className="from-pera"
                                    style={{ color: home[0]?.text_color[i] }}
                                  >
                                    {(e?.description).substring(0, 50)}...
                                  </p>
                                </div>
                                <div className="container">
                                  <hr style={{ color: "#FFAD31" }} />
                                </div>

                                <p
                                  className=" but-try-it"
                                  style={{ margin: "0" }}
                                >
                                  <button
                                    style={{ color: home[0]?.btn_color[i] }}
                                  >
                                    {" "}
                                    TRY IT{" "}
                                  </button>
                                </p>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={e?._id}
                              data-aos="slide-up"
                              data-aos-duration="2000"
                              className="col-lg-3 col-xl-3 nopass"
                              style={{
                                textJustify: "inter-word",
                                alignItems: "center",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigate(`/product/${e?.slug}`);
                                window.scrollTo(0, 0);
                              }}
                            >
                              <div
                                className="from-under"
                                style={{ background: home[0]?.bg[i] }}
                              >
                                <div className="homes-froms-unders">
                                  <img
                                    style={{ pointerEvents: "none" }}
                                    className="libox container"
                                    src={`${url}/product/${e?.image}`}
                                    alt="img error"
                                  />
                                </div>
                                <div style={{ margin: "16px 0" }}>
                                  <p
                                    style={{ color: home[0]?.title_color[i] }}
                                    className="from-heading"
                                  >
                                    {e?.name}
                                  </p>
                                  {/* {e?.caption?.length > 0 ? ( */}
                                  {/* <p
                                className="from-pera"
                                style={{ color: "white" }}
                              >
                                {e?.caption}
                              </p> */}
                                  {/* ) : (
                                <p>""</p>
                              )} */}
                                  <p
                                    className="from-pera"
                                    style={{ color: home[0]?.text_color[i] }}
                                  >
                                    {(e?.description).substring(0, 50)}...
                                  </p>
                                </div>
                                <div className="container">
                                  <hr style={{ color: "#FFAD31" }} />
                                </div>

                                <p
                                  className=" but-try-it"
                                  style={{ margin: "0" }}
                                >
                                  {" "}
                                  <button
                                    style={{ color: home[0]?.btn_color[i] }}
                                  >
                                    {" "}
                                    TRY IT{" "}
                                  </button>
                                </p>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <CustomArrows />
        </section>
      </div>
    </>
  );
};

export default Home;
