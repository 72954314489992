import React from "react";
import "./Style/About.css";
import aboutbaner from "./images/aboutbanner.jpg";
import leafright from "./images/leafright.png";
import nowater from "./images/nowater.png";
import noegg from "./images/noegg.png";
import nogeltain from "./images/nogeltain.png";
import madewithcare from "./images/madewithcare.png";
import noalchohol from "./images/noalchohol.png";
import friendly from "./images/friendly.png";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const About = () => {
  const [about, setAbout] = useState({});
  let url = process.env.REACT_APP_URL;
  const getAbout = async () => {
    try {
      let data = await fetch(`${url}/about`);
      data = await data.json();
      setAbout(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAbout();
  });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Desi Kothi Ice Cream</title>
        <meta
          name="description"
          content="Learn about the enthusiasm behind DesiKothi ice cream. Our journey began with a dedication to high quality and flavour and continues with every spoon we serve."
        />
        <link rel="canonical" href={`${url}/about`} />
      </Helmet>
      <div className="no-copy-text">
        <div>
          <img
            className="about-banner"
            src={`${url}/about/${about[0]?.image}`}
            alt="img error"
          />
        </div>
        {about?.length &&
          about.map((e) => {
            return (
              e && (
                <section
                  key={e?._id}
                  className="py-5"
                  style={{ background: "black" }}
                >
                  <div className="container">
                    <h1 className="aboutheading">About us</h1>
                    <p className="aboutperagraph ">{e?.about_detail}</p>
                  </div>
                </section>
              )
            );
          })}
        {about?.length &&
          about.map((e) => {
            return (
              e && (
                <section
                  key={e?._id}
                  className="service-details-page pdt-70 pdb-90 pb-5  dtt   "
                  style={{ width: "100%", background: "#000" }}
                >
                  {e?.description && (
                    <div className="container classyborder ">
                      <div className="row  ">
                        <div className="col-xl-12 col-lg-12 ">
                          <div className="service-detail-text ">
                            <div
                              className="row d-flex"
                              style={{ alignItems: "center" }}
                            >
                              {/* <div className="col-xl-1">
                            <img
                              className="leaf1"
                              src={leafright}
                              alt="img error"
                            />
                          </div> */}

                              <div
                                className="col-lg-12 col-xl-12 "
                                style={{
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <div>
                                  <h1 className="aboutheading1 ">
                                    Description
                                  </h1>
                                  <p className="aboutperagraph1">
                                    {e?.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </section>
              )
            );
          })}

        <section
          className="service-details-page pdt-70 pdb-90 pb-2   "
          style={{ width: "100%", background: "black" }}
        >
          <div className="container  ">
            <div className="row  ">
              <div className="col-xl-12 col-lg-12 ">
                <div className="service-detail-text ">
                  <div className="row d-flex" style={{ alignItems: "center" }}>
                    <div className="col-lg-2 col-xl-2 noeggno">
                      <div className="nowater  " style={{ height: "227px" }}>
                        <img
                          className="grayscale"
                          src={nowater}
                          alt="img error"
                        />
                        <p className="noadded">No Added Water</p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xl-2 noeggno ">
                      <div className="nowater  " style={{ height: "227px" }}>
                        <img
                          className="grayscale"
                          src={noegg}
                          alt="img error"
                        />
                        <p className="noadded">No Egg</p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xl-2 noeggno ">
                      <div className="nowater  " style={{ height: "227px" }}>
                        <img
                          className="grayscale"
                          src={nogeltain}
                          alt="img error"
                        />
                        <p className="noadded">No Gelatin</p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xl-2 noeggno ">
                      <div className="nowater  " style={{ height: "227px" }}>
                        <img
                          className="grayscale"
                          src={madewithcare}
                          alt="img error"
                        />
                        <p className="noadded">Made With Care</p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xl-2 noeggno ">
                      <div className="nowater  " style={{ height: "227px" }}>
                        <img
                          className="grayscale"
                          src={noalchohol}
                          alt="img error"
                        />
                        <p className="noadded">No Alcohol</p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xl-2 noeggno ">
                      <div className="nowater  " style={{ height: "227px" }}>
                        <img
                          className="grayscale"
                          src={friendly}
                          alt="img error"
                        />
                        <p className="noadded">Vegetarian Friendly</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default About;
